import MultiRangeSlider from "multi-range-slider-react";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import CartContext from "../context/CartContext";

const FilterDropdownSlider = ({
	title,
	values,
	minMaxLimits,
	radio,
	setter,
	addPoundSign,
	filterValues,
	setter2,
	toggleValue,
	valId,
	capacity,
	setShowForm,
}) => {
	const { settingVal } = React.useContext(CartContext);
	const [minValue, setMinValue] = useState(values.min);
	const [maxValue, setMaxValue] = useState(values.max);

	const [minCaptionValue, setMinCaptionValue] = useState(values.min);
	const [maxCaptionValue, setMaxCaptionValue] = useState(values.max);
	const [minInputValue, setMinInputValue] = useState(values.min);
	const [maxInputValue, setMaxInputValue] = useState(values.max);

	const [minValuePerDesk, setMinValuePerDesk] = useState(values.minPerDesk);
	const [maxValuePerDesk, setMaxValuePerDesk] = useState(values.maxPerDesk);
	const [perDesk, setPerDesk] = useState(values.perDesk);

	const [resetTemp, setResetTemp] = useState(false);

	const [minValueLabel, setMinValueLabel] = useState(`${minMaxLimits.min}`);
	const [maxValueLabel, setMaxValueLabel] = useState(`${minMaxLimits.max}`);
	const [minValueLabelPerDesk, setMinValueLabelPerDesk] = useState(
		`£${minMaxLimits.minPerDesk}`
	);
	const [maxValueLabelPerDesk, setMaxValueLabelPerDesk] = useState(
		`£${minMaxLimits.maxPerDesk}`
	);

	if (addPoundSign) {
		setMinValueLabel(`Min price: £${minMaxLimits.min}`);
		setMaxValueLabel(`Max price: £${minMaxLimits.max}`);
	}

	const handleChange = (e) => {
		if (radio && perDesk) {
			setMinValuePerDesk(e.minValue);
			setMaxValuePerDesk(e.maxValue);
			setMinInputValue(e.minValue);
			setMaxInputValue(e.maxValue);
		} else {
			setMinValue(e.minValue);
			setMaxValue(e.maxValue);
			setMinInputValue(e.minValue);
			setMaxInputValue(e.maxValue);
		}
	};

	const handleMinChange = (e) => {
		if (radio && perDesk) {
			setMinValuePerDesk(e.target.value);
			setMinInputValue(e.target.value);
		} else {
			setMinValue(e.target.value);
			setMinInputValue(e.target.value);
		}
	};
	const handleMaxChange = (e) => {
		if (e.target.value > minInputValue) {
			if (radio && perDesk) {
				setMaxValuePerDesk(e.target.value);
				setMaxInputValue(e.target.value);
			} else {
				setMaxValue(e.target.value);
				setMaxInputValue(e.target.value);
			}
		}
	};

	const handleInput = (e) => {
		if (!perDesk && addPoundSign) {
			setMinCaptionValue("£" + kForThousands(e.minValue));
			setMaxCaptionValue("£" + kForThousands(e.maxValue));
			setMinInputValue(e.minValue);
			setMaxInputValue(e.maxValue);
		} else if (perDesk && addPoundSign) {
			setMinCaptionValue("£" + e.minValue);
			setMaxCaptionValue("£" + e.maxValue);
			setMinInputValue(e.minValue);
			setMaxInputValue(e.maxValue);
		} else {
			setMinCaptionValue(e.minValue);
			setMaxCaptionValue(e.maxValue);
			setMinInputValue(e.minValue);
			setMaxInputValue(e.maxValue);
		}
	};

	const resetting = () => {
		var temp = minMaxLimits;

		settingVal(temp, valId);

		if (radio) {
			var temp = minMaxLimits;
			setMinValuePerDesk(temp.minPerDesk);
			setMaxValuePerDesk(temp.maxPerDesk);
			setMinValue(temp.min);
			setMaxValue(temp.max);
		} else {
			var temp = minMaxLimits;
			setMinValue(temp.min);
			setMaxValue(temp.max);
		}
	};

	const radioSetter = () => {
		setPerDesk(!perDesk);
	};

	const kForThousands = (number) =>
		number > 2000 ? `${Math.round(number / 1000)}k` : number;

	useEffect(() => {
		if (radio) {
			const valNew = {
				max: maxValue,
				min: minValue,
				maxPerDesk: maxValuePerDesk,
				minPerDesk: minValuePerDesk,
				perDesk: perDesk,
			};
			valNew.perDesk = perDesk;
			setter(valNew);
		} else if (!radio && maxValue !== 75) {
			const valNew = { max: maxValue, min: minValue, reset: false };
			setter(valNew);
		} else if (!radio) {
			const valNew = { max: maxValue, min: minValue, reset: true };
			setter(valNew);
		}
	}, [minValue, maxValue, minValuePerDesk, maxValuePerDesk, perDesk]);

	useEffect(() => {
		if (radio) {
			var temp = filterValues;
			setMinValuePerDesk(temp.minPerDesk);
			setMaxValuePerDesk(temp.maxPerDesk);
			setMinValue(temp.min);
			setMaxValue(temp.max);
			setPerDesk(temp.perDesk);
		} else {
			var temp = filterValues;
			setMinValue(temp.min);
			setMaxValue(temp.max);
		}
	}, [setter2, toggleValue]);

	return (
		<>
			<Row>
				<Col>
					<h3 className="text-primary fs-5">{title}</h3>
				</Col>
			</Row>
			{radio && (
				<Row>
					<Col xs={6}>
						<Form.Check
							checked={!perDesk}
							onClick={radioSetter}
							type="radio"
							label="Total"
							className="pb-1"
						/>
					</Col>
					<Col xs={6}>
						<Form.Check
							checked={perDesk}
							onClick={radioSetter}
							type="radio"
							label="Per Desk"
							className="pb-1"
						/>
					</Col>
				</Row>
			)}
			<MultiRangeSlider
				className="border-0 multi-slider"
				min={perDesk ? minMaxLimits.minPerDesk : minMaxLimits.min}
				max={perDesk ? minMaxLimits.maxPerDesk : minMaxLimits.max}
				// step={5}
				labels={["", ""]}
				minValue={perDesk ? minValuePerDesk : minValue}
				maxValue={perDesk ? maxValuePerDesk : maxValue}
				// steps={5000}
				step={radio ? (!perDesk ? 5000 : 10) : 1}
				stepOnly={radio ? true : false}
				ruler={false}
				minCaption={minCaptionValue}
				maxCaption={maxCaptionValue}
				onChange={(e) => {
					handleChange(e);
				}}
				onInput={(e) => {
					handleInput(e);
				}}
			/>
			<Row>
				<Col lg={8}>
					{addPoundSign && (
						<div style={{ fontSize: "90%" }}>
							<span>Min price: £</span>
							<input
								style={{ border: "0px" }}
								value={minInputValue}
								onChange={(e) => {
									handleMinChange(e);
								}}
							></input>
						</div>
					)}

					{!perDesk && !addPoundSign && (
						<div style={{ fontSize: "90%" }}>
							<span>Min Desks: </span>
							<input
								style={{ border: "0px" }}
								value={minInputValue}
								onChange={(e) => {
									handleMinChange(e);
								}}
							></input>
						</div>
					)}
				</Col>
				<Col lg={4} className="text-end">
					{addPoundSign && (
						<div style={{ fontSize: "90%" }}>
							<span>Max price: £</span>
							<input
								style={{ border: "0px", maxWidth: "70px" }}
								value={maxInputValue}
								onChange={(e) => {
									handleMaxChange(e);
								}}
							></input>
						</div>
					)}

					{!perDesk && !addPoundSign && (
						<div style={{ fontSize: "90%" }}>
							<span>Max Desks: </span>
							<input
								style={{ border: "0px", maxWidth: "40px" }}
								value={maxInputValue}
								onChange={(e) => {
									handleMaxChange(e);
								}}
							></input>
						</div>
					)}
				</Col>
			</Row>
			<Row style={{ borderTop: "1px solid #C9C9C9" }} className="pt-3 mt-3">
				<Col xs={6}>
					<p
						onClick={() => resetting()}
						className="d-inline-block pb-0 mb-0"
						style={{ cursor: "pointer" }}
					>
						RESET
					</p>
				</Col>
				<Col className="text-end " xs={6}>
					<Dropdown.Item className="bg-t d-inline-block  ">
						<p
							onClick={() => settingVal(values, valId)}
							className="d-inline-block link pb-0 mb-0"
							style={{ cursor: "pointer" }}
						>
							APPLY
						</p>
					</Dropdown.Item>
				</Col>
			</Row>
		</>
	);
};

export default FilterDropdownSlider;
