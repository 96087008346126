import React, { useEffect, useRef, useState } from "react";
import { Col, DropdownButton, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { BsSliders } from "react-icons/bs";
import CartContext from "../context/CartContext";
import DoubleRangeSlider from "./double-range-slider";

function FilterModal({
	showFilter,
	handleClose,
	handleShow,
	resetting,
	setResetting,
	tempFeatures,
	setTempFeatures,
	radius,
	setRadius,
}) {
	const RADIUS_ARR = [0.25, 0.5, 1, 3, 5];

	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,
		onLocationChange,
		numberOfPeople,
		budgetPerDesk,
		options,
		filteredOffices,
		newServiceOptions,
		newAmenitiesOptions,
		buttonChecked,
		newOptions,
		getDistanceFromLatLonInKm,
		distancePoint,
		distancePanPoint,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		capacityMinMax,
		budgetMinMax,
		distanceAmount,
	} = React.useContext(CartContext);

	const budgetInput = useRef(null);

	const OnFeatureClick = (f) => {
		var featuresTemp = tempFeatures.slice();

		if (featuresTemp.includes(f)) {
			const index = featuresTemp.indexOf(f);
			if (index > -1) {
				featuresTemp.splice(index, 1);
			}
		} else if (!featuresTemp.includes(f)) {
			featuresTemp.push(f);
		}
		setTempFeatures(featuresTemp);
	};

	const resetRadius = () => {
		setRadius(1);

		settingVal(1, "distanceAmount");
	};

	// const [budget, setBudget] = useState(
	//   budgetPerDesk > 0 ? budgetPerDesk : "Budget"
	// );

	// const poundSignOrBudget = (budgetArg, initialState) => {
	//   // return Number.isInteger(Number(budgetArg)) && budgetArg !== ""
	//   if (initialState) return +budgetArg > 0 ? `£${budgetArg}` : "Budget";
	//   return Number.isInteger(Number(budgetArg)) && budgetArg !== ""
	//     ? `£${budgetArg}`
	//     : "Budget";
	// };

	// const rounder = (unrounded) => {
	//   return Math.ceil(unrounded / 100) * 100 - 200;
	// };

	// const [showBudget, setShowBudget] = useState(
	//   poundSignOrBudget(budgetPerDesk, true)
	// );

	// const [budgetSlider, setBudgetSlider] = useState(
	//   budgetPerDesk < 100 ? 0 : logPosition(budgetPerDesk)
	// );

	// const setCapacityHelper = (numPeople) => {
	//   return {
	//     min: numPeople.min > 0 ? numPeople.min : 0,
	//     max: numPeople.max > 0 ? numPeople.max : 200,
	//   };
	// };

	// const [capacity, setCapacity] = useState(
	//   numberOfPeople > 0 ? numberOfPeople : "Capacity"
	// );

	// const [showCapacity, setShowCapacity] = useState(
	//   numberOfPeople > 0 ? numberOfPeople : "Capacity"
	// );

	// const setCapacityHelper = (numPeople) => {
	//   // console.log("setCapacityHelper", numPeople);
	//   return {
	//     min: numPeople?.min > 0 ? numPeople?.min : capacityMinMax?.min,
	//     max: numPeople?.max > 0 ? numPeople?.max : capacityMinMax?.max,
	//   };
	// };

	const [capacity, setCapacity] = useState("default");

	// const setBudgetHelper = (budget) => {
	//   return {
	//     min: budget?.min > 0 ? budget?.min : budgetMinMax?.minPropertyBudget,
	//     max: budget?.max > 0 ? budget?.max : budgetMinMax?.maxPropertyBudget,
	//     perDesk: budget?.perDesk,
	//   };
	// };

	const [budget, setBudget] = useState("default");

	// const [capacitySlider, setCapacitySlider] = useState(numberOfPeople);

	// const logSlider = (position) => {
	//   // position will be between 0 and 100
	//   const minPosition = 0;
	//   const maxPosition = 100;

	//   // The result should be between 100 an 10000000
	//   const minValue = Math.log(100);
	//   const maxValue = Math.log(100150);

	//   // calculate adjustment factor
	//   const scale = (maxValue - minValue) / (maxPosition - minPosition);
	//   const result = Math.exp(minValue + scale * (position - minPosition));
	//   return result;
	// };

	// function logPosition(value) {
	//   // if (value < )
	//   const minPosition = 0;
	//   const maxPosition = 100;

	//   // The result should be between 100 an 100000
	//   const minValue = Math.log(100);
	//   const maxValue = Math.log(100150);

	//   // calculate adjustment factor
	//   const scale = (maxValue - minValue) / (maxPosition - minPosition);
	//   const result = (Math.log(value) - minValue) / scale + minPosition;

	//   return result;
	// }

	const onApplyFilters = () => {
		// console.log(capacity);
		// console.log(budget, showBudget);
		settingVal(radius, "distanceAmount");
		settingVal(budget, "budgetPerDesk");
		settingVal(capacity, "numberOfPeople");
		settingVal(typeSetTerm, "selectedOffice");
		settingVal(sortSetTerm, "sortedBy");
		settingVal(tempFeatures, "selectedFeatures");
	};

	const OnClearFeatures = () => {
		setTempFeatures([]);
		settingVal([], "selectedFeatures");
	};

	const [showAmenities, setShowAmenities] = useState(false);
	const [showServices, setShowServices] = useState(false);

	const [typeRadioValue, setTypeRadioValue] = useState("1");
	const [typeSetTerm, setTypeSetTerm] = useState("Any");

	const [sortRadioValue, setSortRadioValue] = useState("1");
	const [sortSetTerm, setSortSetTerm] = useState("Price");

	const typeRadios = [
		{ name: "Any", value: "1", setTerm: "Any" },
		{ name: "Managed", value: "2", setTerm: "Managed Office" },
		{ name: "Serviced", value: "3", setTerm: "Serviced Office" },
		{ name: "Flex Lease", value: "3", setTerm: "Flex Lease" },
	];

	const sortRadios = [
		{ name: "Lowest Price", value: "1", setTerm: "Lowest Price" },
		{ name: "Highest Price", value: "3", setTerm: "Highest Price" },
		{ name: "Distance", value: "2", setTerm: "Distance" },
	];
	useEffect(() => {
		if (numberOfPeople !== "default") {
			var temp = numberOfPeople;
			setCapacity(temp);
		}
	}, [numberOfPeople]);
	useEffect(() => {
		if (budgetPerDesk !== "default") {
			var temp = budgetPerDesk;
			setBudget(temp);
		}
	}, [budgetPerDesk]);

	useEffect(() => {
		if (selectedOfficeType === "Office Type" || selectedOfficeType === "Any") {
			setTypeRadioValue("1");
			setTypeSetTerm("Any");
		}
		if (selectedOfficeType === "Managed Office") {
			setTypeRadioValue("2");
			setTypeSetTerm("Managed Office");
		}
		if (selectedOfficeType === "Serviced Office") {
			setTypeRadioValue("3");
			setTypeSetTerm("Serviced Office");
		}
		if (selectedOfficeType === "Flex Lease") {
			setTypeRadioValue("4");
			setTypeSetTerm("Flex Lease");
		}
	}, [selectedOfficeType]);

	useEffect(() => {
		if (sortedBy === "Sort" || sortedBy === "Distance") {
			setSortRadioValue("2");
			setSortSetTerm("Distance");
		}
		if (sortedBy === "Lowest Price") {
			setSortRadioValue("1");
			setSortSetTerm("Lowest Price");
		}
		if (sortedBy === "Highest Price") {
			setSortRadioValue("3");
			setSortSetTerm("Highest Price");
		}
	}, [sortedBy]);

	const resetAll = () => {
		// setBudget(budgetPerDesk > 0 ? budgetPerDesk : "Budget");
		// setShowBudget(poundSignOrBudget(budgetPerDesk, true));
		// setBudgetSlider(budgetPerDesk < 100 ? 0 : logPosition(budgetPerDesk));
		// setCapacity(numberOfPeople > 0 ? numberOfPeople : "Capacity");
		// setCapacitySlider(numberOfPeople);
		settingVal(1, "distanceAmount");
		settingVal(budgetMinMax, "budgetPerDesk");
		settingVal(capacityMinMax, "numberOfPeople");
		settingVal("Any", "selectedOffice");
		settingVal("Distance", "sortedBy");
		settingVal([], "selectedFeatures");
		setTempFeatures([]);

		setResetting(!resetting);
	};

	const kForThousands = (number) =>
		number > 1000 ? `${Math.round(number / 1000)}k` : number;

	// document.documentElement.classList.add("js");

	return (
		<>
			<a
				style={{ cursor: "pointer" }}
				onClick={handleShow}
				className="text-dark-grey py-2  text-center  d-inline-block"
			>
				<BsSliders className="fs-2 text-center" />
				<p className="py-2  pb-0 mb-0  d-block">Filters</p>
			</a>

			<Modal show={showFilter} onHide={handleClose} animation={false}>
				{/* <div className="position-relative"> */}
				<Modal.Header closeButton>
					<Modal.Title className="fs-3">Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div id="copy" className="mb-0 position-relative js">
						{/* BUDGET */}
						<div className="w-100 h-100 border-pale-grey text-black drop-font-size">
							<h3 className="mb-3">Budget</h3>
							<div className="text-center px-1">
								<div className="d-flex justify-content-center pb-3">
									{/* <div className="w-75 d-flex justify-content-center"> */}
									<span>
										{!budget?.perDesk
											? "£" + (kForThousands(budget?.min) || 0)
											: "£" + (budget?.minPerDesk || 0)}{" "}
										<span className="px-4">-</span>{" "}
										{!budget?.perDesk
											? "£" +
											  (kForThousands(budget?.max) ||
													kForThousands(budgetMinMax?.max))
											: "£" + (budget?.maxPerDesk || budgetMinMax?.maxPerDesk)}
										{/* </div> */}
									</span>
								</div>
								<DoubleRangeSlider
									addPoundSign
									values={budget}
									filterValues={budgetPerDesk}
									valId="budgetPerDesk"
									radio
									minMaxLimits={budgetMinMax}
									title="Budget"
									setter={setBudget}
									setter2={resetting}
								/>
								{/* <input
                  ref={budgetInput}
                  className="border border-med-grey border-1 rounded-pill py-2 px-4 text-center mx-auto my-3"
                  style={{ verticalAlign: "middle" }}
                  placeholder="Any"
                  type="text"
                  value={showBudget}
                  onChange={(e) => {
                    setBudget(e.target.value);
                    setShowBudget(e.target.value);
                    setBudgetSlider(
                      e.target.value < 100 ? 0 : logPosition(e.target.value)
                    );
                  }}
                  onFocus={(e) => {
                    const newValue =
                      e.currentTarget.value === "budget"
                        ? e.currentTarget.value
                        : e.currentTarget.value.slice(1);
                    e.currentTarget.value = newValue;
                    e.currentTarget.type = "number";
                    e.currentTarget.placeholder = "";
                    if (budget === "Budget") {
                      setBudget("");
                      setShowBudget("");
                    }
                  }}
                  onBlur={(e) => {
                    console.log(e.currentTarget.value);
                    e.currentTarget.type = "text";
                    e.currentTarget.placeholder = "Budget";
                    if (Number.isInteger(Number(budget)) && budget !== "") {
                      setBudget(budget);
                      setShowBudget(poundSignOrBudget(budget));
                    } else {
                      setBudget("Budget");
                      setShowBudget("Budget");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.target.blur();
                  }}
                /> */}

								{/* <RangeSlider
                  value={budgetSlider ? budgetSlider : 0}
                  min={0}
                  max={100}
                  step={1}
                  tooltip="off"
                  style={{
                    width: "100%",
                    "--min": 0,
                    "--max": 100,
                    "--val": budgetSlider ? budgetSlider : 0,
                  }}
                  onChange={(changeEvent) => {
                    budgetInput.current.blur();
                    const newBudget = logSlider(changeEvent.target.value);
                    setBudgetSlider(
                      budget < 100 || budget === "Budget"
                        ? 0
                        : logPosition(budget)
                    );

										setBudget(newBudget);

                    setShowBudget(poundSignOrBudget(rounder(newBudget)));
                  }}
                  variant="primary"
                /> */}
							</div>
							<hr className="mx-4 py-2 text-med-grey" />
						</div>
						{/* CAPACITY */}
						<div className=" rounded-pill border-pale-grey text-black me-2">
							<h3 className="mb-3">Capacity</h3>
							<div className="text-center px-1">
								<div className="d-flex justify-content-center pb-3">
									{/* <div className="w-75 d-flex justify-content-center"> */}
									<span>
										{capacity?.min || 1}
										<span className="px-4">-</span>
										{(capacity?.max || capacityMinMax?.max) + " " + "Desks"}
									</span>
									{/* </div> */}
								</div>
								<DoubleRangeSlider
									values={capacity}
									filterValues={numberOfPeople}
									valId="numberOfPeople"
									minMaxLimits={capacityMinMax}
									title="Capacity"
									setter={setCapacity}
									setter2={resetting}
								/>
								{/* <input
                  className="border border-med-grey border-1 rounded-pill py-2 px-4 text-center mx-auto my-3"
                  style={{ verticalAlign: "middle" }}
                  placeholder="Capacity"
                  type="text"
                  value={capacity}
                  onChange={(e) => {
                    setCapacity(e.target.value);
                    setCapacitySlider(e.target.value);
                  }}
                  onFocus={(e) => {
                    e.currentTarget.type = "number";
                    e.currentTarget.placeholder = "";
                    if (capacity === "Capacity") {
                      setCapacity("");
                    }
                  }}
                  onBlur={(e) => {
                    e.currentTarget.type = "text";
                    e.currentTarget.placeholder = "Capacity";
                    if (Number.isInteger(Number(capacity)) && capacity !== "") {
                      setCapacity(capacity);
                    } else {
                      setCapacity("Capacity");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.target.blur();
                  }}
                />
                <RangeSlider
                  value={capacitySlider > 0 ? capacitySlider : 0}
                  min={0}
                  max={400}
                  step={1}
                  tooltip="off"
                  onChange={(changeEvent) => {
                    const newCapacity = changeEvent.target.value;
                    setCapacitySlider(newCapacity);
                    setCapacity(newCapacity);
                    // setShowCapacity(newCapacity);
                  }}
                  style={{
                    width: "100%",
                    "--min": 0,
                    "--max": 400,
                    "--val": capacitySlider > 0 ? capacitySlider : 0,
                  }}
                /> */}
							</div>
							<hr className="mx-4 py-2 text-med-grey" />
						</div>
						<div>
							<h3 className="mb-3">Radius</h3>
							<div className="px-1 pb-4">
								<div className="d-flex ">
									<DropdownButton
										style={{
											borderRadius: "6px",
											width: "8rem",
										}}
										className={` filter-button-div prop-dropdown-2 toggle-100 course-directory text-center bg-${
											distanceAmount !== 1 ? "primary" : "white"
										} border border-1 border-${
											distanceAmount !== 1 ? "primary" : "new-grey"
										} ${
											distanceAmount !== 1
												? "feature-selected"
												: "feature-unselected"
										} py-0 py-0 me-2 drop-font-size`}
										variant="none"
										title={
											<span>
												{"+" +
													" " +
													(radius || 1) +
													" " +
													"mile" +
													(radius !== 1 ? "s" : "")}
											</span>
										}
									>
										<Row className="w-100">
											<Col>
												<div
													style={{
														borderRadius: "15px",
													}}
													className="px-4 bg-white py-3 border border-new-grey"
												>
													<div style={{ width: "100%" }}>
														<Row>
															<Col>
																<h3 className="text-primary fs-5">Radius</h3>
															</Col>
														</Row>
														<Row>
															{RADIUS_ARR.map((radiusItem) => {
																return (
																	<div
																		className={`radius-divs ${
																			radiusItem === radius
																				? "radius-divs-selected"
																				: ""
																		}`}
																		style={{ cursor: "pointer" }}
																		onClick={() => setRadius(radiusItem)}
																	>
																		<span>
																			{radiusItem} mile
																			{radiusItem !== 1 ? "s" : ""}
																		</span>
																	</div>
																);
															})}
														</Row>
													</div>
												</div>
											</Col>
										</Row>
									</DropdownButton>
								</div>
							</div>
							<hr className="mx-4 py-2 text-med-grey" />
						</div>
						{/* TYPE */}
						{/* <div>
							{" "}
							<div className="d-flex justify-content-between">
								<h3 className="mb-3">Type</h3>
								<p
									className="ps-1 mt-2 pe-4 text-primary"
									onClick={() => {
										setTypeRadioValue("1");
										setTypeSetTerm("Any");
									}}
									style={{ cursor: "pointer" }}
								>
									Clear selection
								</p>
							</div>
							<div className="px-1">
								<div className=" d-flex justify-content-around">
									<ToggleButtonGroup
										type="radio"
										name="typeOptions"
										className="w-100 "
									>
										{typeRadios.map((radio, idx) => {
											return (
												<ToggleButton
													className={`text-${
														typeRadioValue === radio.value ? "white" : "black"
													} border-${
														typeRadioValue === radio.value ? "primary" : "black"
													} bg-${
														typeRadioValue === radio.value ? "primary" : "white"
													}`}
													style={{ width: "33%" }}
													key={idx}
													id={`type-radio-${idx}`}
													type="radio"
													//   variant="outline-primary"
													name="typeRadio"
													value={radio.value}
													checked={typeRadioValue === radio.value}
													onChange={(e) => {
														setTypeRadioValue(e.currentTarget.value);
														setTypeSetTerm(radio.setTerm);
													}}
												>
													{radio.name}
												</ToggleButton>
											);
										})}
									</ToggleButtonGroup>
								</div>
							</div>
							<hr className="mx-4 py-2 text-med-grey" />
						</div> */}
						{/* SORT */}
						<div>
							<h3 className="mb-3">Sort</h3>
							<div className="px-1">
								<div className="d-flex justify-content-around">
									<ToggleButtonGroup
										type="radio"
										name="sortOptions"
										className="w-100 "
									>
										{sortRadios?.map((radio, idx) => {
											return (
												<ToggleButton
													// className="px-4 text-black border-black rounded-pill"
													className={` d-flex align-items-center justify-content-center  text-${
														sortRadioValue === radio.value ? "white" : "black"
													} border-${
														sortRadioValue === radio.value ? "primary" : "black"
													} bg-${
														sortRadioValue === radio.value ? "primary" : "white"
													}`}
													style={{ width: "33%" }}
													key={idx}
													id={`sort-radio-${idx}`}
													type="radio"
													variant="outline-primary"
													name="sortRadio"
													value={radio.value}
													checked={sortRadioValue === radio.value}
													// onClick={(e) => console.log(e.currentTarget)}
													onChange={(e) => {
														setSortSetTerm(radio.setTerm);
														setSortRadioValue(e.currentTarget.value);
													}}
												>
													<span style={{ fontSize: "80%" }}>{radio.name}</span>
												</ToggleButton>
											);
										})}
									</ToggleButtonGroup>
								</div>
							</div>
							<hr className="mx-4 py-2 text-med-grey" />
						</div>
						{/* FEATURES */}
						<div>
							<div className="d-flex justify-content-between">
								<h3 className="mb-3">Features</h3>
								<p
									onClick={() => OnClearFeatures()}
									className="d-inline-block text-primary pe-4"
									style={{ cursor: "pointer" }}
								>
									Clear selection
								</p>
							</div>
							<div className="pb-3 px-1">
								<h4 className="fs-4 fw-lighter">Amenites</h4>
								{newAmenitiesOptions?.slice(0, 4).map((amenity, i) => (
									<Form.Check
										checked={tempFeatures.includes(amenity)}
										onClick={() => OnFeatureClick(amenity)}
										type="checkbox"
										label={amenity}
									/>
								))}
								{!showAmenities && (
									<a
										className="d-block pt-3 pb-2 my-0"
										onClick={() => setShowAmenities(true)}
									>
										See all
									</a>
								)}
								{showAmenities &&
									newAmenitiesOptions
										?.slice(4)
										.map((amenity, i) => (
											<Form.Check
												checked={tempFeatures?.includes(amenity)}
												onClick={() => OnFeatureClick(amenity)}
												type="checkbox"
												label={amenity}
											/>
										))}
								{showAmenities && (
									<a
										className="d-block pt-3 pb-2 my-0"
										onClick={() => setShowAmenities(false)}
									>
										Hide
									</a>
								)}
							</div>
							<div className="pb-1">
								<h4 className="fs-4 fw-lighter">Services</h4>
								{newServiceOptions?.slice(0, 4).map((service, i) => (
									<Col key={i} xs={12} md={6} lg={4}>
										<Form.Check
											checked={tempFeatures?.includes(service)}
											onClick={() => OnFeatureClick(service)}
											type="checkbox"
											label={service}
										/>
									</Col>
								))}
								{!showServices && (
									<a
										className="d-block pt-3 pb-2 my-0"
										onClick={() => setShowServices(true)}
									>
										See all
									</a>
								)}
								{showServices &&
									newServiceOptions?.slice(4).map((service, i) => (
										<Col key={i} xs={12} md={6} lg={4}>
											<Form.Check
												checked={tempFeatures?.includes(service)}
												onClick={() => OnFeatureClick(service)}
												type="checkbox"
												label={service}
											/>
										</Col>
									))}
								{showServices && (
									<a
										className="d-block pt-3 pb-2 my-0"
										onClick={() => setShowServices(false)}
									>
										Hide
									</a>
								)}
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer
					class
					className="position-sticky sticky-bottom w-100 bg-white"
				>
					<div className="d-flex justify-content-between w-100">
						<p
							style={{ cursor: "pointer" }}
							className="ps-4 my-auto text-primary"
							onClick={() => {
								resetAll();
							}}
						>
							Reset all
						</p>
						<Button
							variant="primary"
							className="text-white"
							onClick={() => {
								handleClose();
								onApplyFilters();
							}}
						>
							Apply
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default FilterModal;
