import React, { useEffect, useState } from "react";
import {
	Col,
	Container,
	Dropdown,
	DropdownButton,
	Form,
	Row,
} from "react-bootstrap";
import CartContext from "../context/CartContext";
import FilterDropdownSlider from "./filter-dropdown-slider";
import { MdOutlineClear } from "react-icons/md";

const PropertiesFilters = ({
	setShowForm,
	features,
	type,
	resetting,
	sorted,
	radius,
	setFeatures,
	setType,
	setResetting,
	setSorted,
	setRadius,
}) => {
	const {
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		numberOfPeople,
		budgetPerDesk,
		newServiceOptions,
		newAmenitiesOptions,
		sortedBy,
		distanceAmount,
		capacityMinMax,
		budgetMinMax,
	} = React.useContext(CartContext);

	// FEATURES

	// const [show, setShow] = useState(false);

	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	const OnFeatureClick = (f) => {
		const featuresTemp = features.slice();

		if (featuresTemp.includes(f)) {
			const index = featuresTemp.indexOf(f);
			if (index > -1) {
				featuresTemp.splice(index, 1);
			}
		} else if (!featuresTemp.includes(f)) {
			featuresTemp.push(f);
		}
		setFeatures(featuresTemp);
	};

	const OnApplyFeatures = () => {
		settingVal(features, "selectedFeatures");
	};

	const OnClearFeatures = () => {
		setFeatures([]);
		settingVal([], "selectedFeatures");
	};

	// TYPE

	//   CAPACITY

	// const setCapacityHelper = (numPeople) => {
	// 	return {
	// 		min: numPeople?.min > 0 ? numPeople?.min : capacityMinMax?.min,
	// 		max: numPeople?.max > 0 ? numPeople?.max : capacityMinMax?.max,
	// 	};
	// };

	const [capacity, setCapacity] = useState("default");

	//   BUDGET

	// const setBudgetHelper = (budget) => {
	// 	return {
	// 		min: budget?.min > 0 ? budget?.min : budgetMinMax?.min,
	// 		max: budget?.max > 0 ? budget?.max : budgetMinMax?.max,
	// 		perDesk: budget?.perDesk,
	// 	};
	// };

	const [budget, setBudget] = useState("default");

	//   SORT

	// RADIUS

	const RADIUS_ARR = [0.25, 0.5, 1, 3, 5];

	//   RESET

	const resetAll = () => {
		setFeatures([]);
		setType("Office Type");
		setResetting(!resetting);
		setSorted("Sort");
		setRadius(1);
		settingVal("Office Type", "selectedOffice");
		settingVal(capacityMinMax, "numberOfPeople");
		settingVal(budgetMinMax, "budgetPerDesk");
		settingVal("Sort", "sortedBy");
		settingVal(1, "distanceAmount");
		settingVal([], "selectedFeatures");
	};

	const resetOfficeType = () => {
		setType("Office Type");

		settingVal("Office Type", "selectedOffice");
	};

	const resetRadius = () => {
		setRadius(1);

		settingVal(1, "distanceAmount");
	};

	const resetSort = () => {
		setSorted("Sort");

		settingVal("Sort", "sortedBy");
	};

	// APPLY
	const [capacityToggleValue, setCapacityToggleValue] = useState(false);
	const [budgetToggleValue, setBudgetToggleValue] = useState(false);
	const applyAll = () => {
		OnApplyFeatures();
		settingVal(type, "selectedOffice");
		settingVal(capacity, "numberOfPeople");
		settingVal(budget, "budgetPerDesk");
		settingVal(sorted, "sortedBy");
		settingVal(radius, "distanceAmount");
	};

	const onDropdownClose = (val, type) => {
		if (type === "Capacity" && val === false) {
			var temp = numberOfPeople;

			setCapacity(temp);
			setCapacityToggleValue(val);
		} else if (type === "Capacity" && val === true) {
			setCapacityToggleValue(val);
		}
		if (type === "Budget" && val === false) {
			var temp = budgetPerDesk;
			setBudget(temp);
			setBudgetToggleValue(val);
		} else if (type === "Budget" && val === true) {
			setBudgetToggleValue(val);
		}
		if (type === "Features") {
			var temp = selectedFeatures;
			setFeatures(temp);
		}
		if (type === "OfficeType") {
			var temp = selectedOfficeType;
			setType(temp);
		}
		if (type === "Distance") {
			var temp = distanceAmount;
			setRadius(temp);
		}
		if (type === "Sort") {
			var temp = sortedBy;
			setSorted(temp);
		}
	};

	const kForThousands = (number) =>
		number > 2000 ? `${Math.round(number / 1000)}k` : number;

	useEffect(() => {
		if (numberOfPeople !== "default") {
			var temp = numberOfPeople;
			setCapacity(temp);
		}
	}, [numberOfPeople]);
	useEffect(() => {
		if (budgetPerDesk !== "default") {
			var temp = budgetPerDesk;
			setBudget(temp);
		}
	}, [budgetPerDesk]);
	// useEffect(() => {
	// 	if (numberOfPeople.max > 75) {
	// 		handleShow();
	// 	}
	// }, [numberOfPeople]);

	return (
		<Row className=" pb-4 align-items-center d-none d-xl-flex">
			{/* <Modal
				style={{ zIndex: 99999 }}
				size="lg"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Body
					style={{ borderRadius: "20px" }}
					className="bg-light-grey"
					closeButton
				>
					<div className="p-lg-4 p-2  pt-md-0 pt-4  position-relative">
						<div className="position-absolute end-0 top-0 p-lg-2">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4 "
								onClick={handleClose}
							/>
						</div>
						<p className="fs-5 d-none d-md-block">
							If you are trying to find space for more than 75 people it would
							be best to contact us directly using this form.
						</p>
						<p className="d-md-none">
							If you are trying to find space for more than 75 people it would
							be best to contact us directly using this form.
						</p>
						<ContactFormHome />
					</div>
				</Modal.Body>
			</Modal> */}
			<Col lg={{ span: 8, offset: 2 }}>
				{" "}
				<div>
					<div id="copy" className="mb-0 d-flex justify-content-center">
						<DropdownButton
							onToggle={(e) => onDropdownClose(e, "Features")}
							className={`four-cols px-2 filter-button-div features-dropdown course-directory rounded-pill bg-${
								selectedFeatures?.length > 0 ? "dark-blue" : "white"
							} border border-1 border-${
								selectedFeatures?.length > 0 ? "dark-blue" : "new-grey"
							} ${
								selectedFeatures?.length > 0
									? "feature-selected"
									: "feature-unselected"
							} py-0 py-0 me-2 drop-font-size four-columns`}
							title="Features"
							variant="none"
							style={{ fontSize: "50%" }}
						>
							<Container>
								<div
									style={{
										borderRadius: "15px",
									}}
									className="px-4 bg-white py-3 border position-relative border-new-grey"
								>
									<div className="position-absolute top-0 end-0 p-3">
										<Dropdown.Item className="bg-t d-inline-block w-30">
											<MdOutlineClear
												style={{ cursor: "pointer" }}
												className="fs-4 "
											/>
										</Dropdown.Item>
									</div>
									<div style={{ width: "100%" }}>
										<Row>
											<Col>
												<h3 className="text-primary fs-5">Services</h3>
											</Col>
										</Row>
										<Row>
											{newServiceOptions?.map((service, i) => (
												<Col key={i} xs={6} md={4} lg={3}>
													<Form.Check
														checked={features?.includes(service)}
														onClick={() => OnFeatureClick(service)}
														type="checkbox"
														label={service}
														className="pb-1"
													/>
												</Col>
											))}
										</Row>
										<Row>
											<Col>
												<h3 className="text-primary mt-4 fs-5">Amenities</h3>
											</Col>
										</Row>
										<Row>
											{newAmenitiesOptions?.map((amenity, i) => (
												<Col key={i} xs={6} md={4} lg={3}>
													<Form.Check
														checked={features?.includes(amenity)}
														onClick={() => OnFeatureClick(amenity)}
														type="checkbox"
														label={amenity}
														className="pb-1"
													/>
												</Col>
											))}
										</Row>
									</div>
									<Dropdown.Divider />
									<Row className="pt-3 ">
										<Col xs={6}>
											<p
												onClick={() => OnClearFeatures()}
												className="d-inline-block"
												style={{ cursor: "pointer" }}
											>
												CLEAR
											</p>
										</Col>
										<Col className="text-end" xs={6}>
											<Dropdown.Item className="bg-t d-inline-block w-30">
												<p
													onClick={() => OnApplyFeatures()}
													className="d-inline-block link"
													style={{ cursor: "pointer" }}
												>
													APPLY
												</p>
											</Dropdown.Item>
										</Col>
									</Row>
								</div>
							</Container>
						</DropdownButton>

						{/* <DropdownButton
							onToggle={(e) => onDropdownClose(e, "OfficeType")}
							className={`px-2 filter-button-div prop-dropdown-1  course-directory rounded-pill bg-${
								selectedOfficeType === "Serviced Office" ||
								selectedOfficeType == "Managed Office"
									? "dark-blue"
									: "white"
							} border border-1 border-${
								selectedOfficeType === "Serviced Office" ||
								selectedOfficeType == "Managed Office"
									? "dark-blue"
									: "new-grey"
							} ${
								selectedOfficeType === "Serviced Office" ||
								selectedOfficeType == "Managed Office"
									? "feature-selected"
									: "feature-unselected"
							} py-0 py-0 me-2 drop-font-size`}
							variant="none"
							title={
								selectedOfficeType === "Select Office Type"
									? "Type"
									: selectedOfficeType
							}
						>
							<Row className="w-100">
								<Col>
									<div
										style={{
											borderRadius: "15px",
										}}
										className="px-4 bg-white py-3 w-100 border border-new-grey"
									>
										<div style={{ width: "100%" }}>
											<Row>
												<Col>
													<h3 className="text-primary fs-5">Office Type</h3>
												</Col>
											</Row>
											<Row>
												<Col xs={6} md={3}>
													<Form.Check
														checked={type === "Any" ? true : false}
														onClick={
															() => setType("Any")
															// settingVal("Any", "selectedOffice")
														}
														type="radio"
														label="Any"
														className="pb-1"
													/>
												</Col>
												<Col xs={6} md={4}>
													<Form.Check
														checked={type === "Serviced Office" ? true : false}
														onClick={() => setType("Serviced Office")}
														type="radio"
														label="Serviced Office"
														className="pb-1"
													/>
												</Col>
												<Col xs={6} md={4}>
													<Form.Check
														checked={type === "Managed Office" ? true : false}
														onClick={() => setType("Managed Office")}
														type="radio"
														label="Managed Office"
														className="pb-1"
													/>
												</Col>
											</Row>
											<Row
												style={{ borderTop: "1px solid #C9C9C9" }}
												className="pt-3 mt-3"
											>
												<Col xs={6}>
													<p
														onClick={() => resetOfficeType()}
														className="d-inline-block pb-0 mb-0"
														style={{ cursor: "pointer" }}
													>
														RESET
													</p>
												</Col>
												<Col className="text-end " xs={6}>
													<Dropdown.Item className="bg-t d-inline-block  ">
														<p
															onClick={() => settingVal(type, "selectedOffice")}
															className="d-inline-block link pb-0 mb-0"
															style={{ cursor: "pointer" }}
														>
															APPLY
														</p>
													</Dropdown.Item>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
						</DropdownButton> */}

						<DropdownButton
							onToggle={(e) => onDropdownClose(e, "Capacity")}
							className={`px-2 filter-button-div prop-dropdown-1 course-directory rounded-pill bg-${
								numberOfPeople !== capacityMinMax &&
								(numberOfPeople?.min > 0 || numberOfPeople?.max < 75)
									? "dark-blue"
									: "white"
							} border border-1 border-${
								numberOfPeople !== capacityMinMax &&
								(numberOfPeople?.min > 0 || numberOfPeople?.max < 75)
									? "dark-blue"
									: "new-grey"
							} ${
								numberOfPeople !== capacityMinMax &&
								(numberOfPeople?.min > 0 || numberOfPeople?.max < 75)
									? "feature-selected"
									: "feature-unselected"
							} py-0 py-0 me-2 drop-font-size`}
							variant="none"
							title={
								numberOfPeople !== capacityMinMax &&
								(numberOfPeople?.min > 0 || numberOfPeople?.max < 75) ? (
									(numberOfPeople?.min || 0) +
									(numberOfPeople?.reset === false
										? " - " + (numberOfPeople?.max || capacityMinMax?.max)
										: "") +
									" Desk" +
									(numberOfPeople?.min !== 1 ? "s" : "")
								) : (
									<span>Capacity</span>
								)
							}
							Z
						>
							<Row className="w-100">
								<Col className="p-4 bg-white border border-new-grey rounded">
									<FilterDropdownSlider
										capacity
										toggleValue={capacityToggleValue}
										values={capacity}
										filterValues={numberOfPeople}
										valId="numberOfPeople"
										minMaxLimits={capacityMinMax}
										title="Capacity"
										setter={setCapacity}
										setter2={resetting}
										resetFunction={setResetting}
									/>
								</Col>
							</Row>
						</DropdownButton>

						<DropdownButton
							onToggle={(e) => onDropdownClose(e, "Budget")}
							className={`px-2 filter-button-div prop-dropdown-1 course-directory rounded-pill bg-${
								budgetPerDesk !== budgetMinMax ? "dark-blue" : "white"
							} border border-1 border-${
								budgetPerDesk !== budgetMinMax ? "dark-blue" : "new-grey"
							} ${
								budgetPerDesk !== budgetMinMax
									? "feature-selected"
									: "feature-unselected"
							} py-0 py-0 me-2 drop-font-size`}
							variant="none"
							title={
								budgetPerDesk !== budgetMinMax ? (
									!budgetPerDesk?.perDesk ? (
										"£" +
										(kForThousands(budgetPerDesk?.min) || 0) +
										" - " +
										"£" +
										(kForThousands(budgetPerDesk?.max) ||
											kForThousands(budgetMinMax?.max))
									) : (
										"£" +
										(budgetPerDesk?.minPerDesk || 0) +
										" - " +
										"£" +
										(budgetPerDesk?.maxPerDesk || budgetMinMax?.maxPerDesk)
									)
								) : (
									<span>Budget</span>
								)
							}
						>
							<Row className="w-100">
								<Col className="p-4 bg-white border border-new-grey rounded">
									<FilterDropdownSlider
										toggleValue={budgetToggleValue}
										addPoundSign
										values={budget}
										filterValues={budgetPerDesk}
										valId="budgetPerDesk"
										radio
										minMaxLimits={budgetMinMax}
										title="Budget"
										setter={setBudget}
										setter2={resetting}
									/>
								</Col>
							</Row>
						</DropdownButton>
						<DropdownButton
							onToggle={(e) => onDropdownClose(e, "Distance")}
							className={`px-2 filter-button-div prop-dropdown-2 course-directory rounded-pill bg-${
								distanceAmount !== 1 ? "dark-blue" : "white"
							} border border-1 border-${
								distanceAmount !== 1 ? "dark-blue" : "new-grey"
							} ${
								distanceAmount !== 1 ? "feature-selected" : "feature-unselected"
							} py-0 py-0 me-2 drop-font-size`}
							variant="none"
							title={
								"+" +
								distanceAmount +
								" " +
								"mile" +
								(distanceAmount !== 1 ? "s" : "")
							}
						>
							<Row className="w-100">
								<Col>
									<div
										style={{
											borderRadius: "15px",
										}}
										className="px-4 bg-white py-3 border border-new-grey"
									>
										<div style={{ width: "100%" }}>
											<Row>
												<Col>
													<h3 className="text-primary fs-5">Radius</h3>
												</Col>
											</Row>
											<Row>
												{RADIUS_ARR.map((radiusItem) => {
													return (
														<div
															className={`radius-divs ${
																radiusItem === radius
																	? "radius-divs-selected"
																	: ""
															}`}
															style={{ cursor: "pointer" }}
															onClick={() => setRadius(radiusItem)}
														>
															<span>
																{radiusItem} mile{radiusItem !== 1 ? "s" : ""}
															</span>
														</div>
													);
												})}
											</Row>
											<Row
												style={{ borderTop: "1px solid #C9C9C9" }}
												className="pt-3 mt-3"
											>
												<Col xs={6}>
													<p
														onClick={() => resetRadius()}
														className="d-inline-block pb-0 mb-0"
														style={{ cursor: "pointer" }}
													>
														RESET
													</p>
												</Col>
												<Col className="text-end " xs={6}>
													<Dropdown.Item className="bg-t d-inline-block  ">
														<p
															onClick={() =>
																settingVal(radius, "distanceAmount")
															}
															className="d-inline-block link pb-0 mb-0"
															style={{ cursor: "pointer" }}
														>
															APPLY
														</p>
													</Dropdown.Item>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
						</DropdownButton>

						<DropdownButton
							onToggle={(e) => onDropdownClose(e, "Sort")}
							className={`px-2 filter-button-div prop-dropdown-3 course-directory rounded-pill bg-${
								sortedBy === "Distance" ||
								sortedBy === "Lowest Price" ||
								sortedBy === "Highest Price"
									? "dark-blue"
									: "white"
							} border border-1 border-${
								sortedBy === "Distance" ||
								sortedBy === "Lowest Price" ||
								sortedBy === "Highest Price"
									? "dark-blue"
									: "new-grey"
							} ${
								sortedBy === "Distance" ||
								sortedBy === "Lowest Price" ||
								sortedBy === "Highest Price"
									? "feature-selected"
									: "feature-unselected"
							} py-0 py-0 me-2 drop-font-size`}
							variant="none"
							title={sortedBy}
						>
							<Row className="w-100">
								<Col>
									<div
										style={{
											borderRadius: "15px",
										}}
										className="px-4 bg-white py-3 border border-new-grey"
									>
										<div style={{ width: "100%" }}>
											<Row>
												<Col>
													<h3 className="text-primary fs-5">Sort by</h3>
												</Col>
											</Row>
											<Row>
												<Col xs={4}>
													<Form.Check
														checked={sorted === "Distance" ? true : false}
														onClick={() => setSorted("Distance")}
														type="radio"
														label="Distance"
														className="pb-1"
													/>
												</Col>
												<Col xs={4}>
													<Form.Check
														checked={sorted === "Highest Price" ? true : false}
														onClick={() => setSorted("Highest Price")}
														type="radio"
														label="Highest Price"
														className="pb-1"
													/>
												</Col>
												<Col xs={4}>
													<Form.Check
														checked={sorted === "Lowest Price" ? true : false}
														onClick={() => setSorted("Lowest Price")}
														type="radio"
														label="Lowest Price"
														className="pb-1"
													/>
												</Col>
											</Row>
											<Row
												style={{ borderTop: "1px solid #C9C9C9" }}
												className="pt-3 mt-3"
											>
												<Col xs={6}>
													<p
														onClick={() => resetSort()}
														className="d-inline-block pb-0 mb-0"
														style={{ cursor: "pointer" }}
													>
														RESET
													</p>
												</Col>
												<Col className="text-end " xs={6}>
													<Dropdown.Item className="bg-t d-inline-block  ">
														<p
															onClick={() => settingVal(sorted, "sortedBy")}
															className="d-inline-block link pb-0 mb-0"
															style={{ cursor: "pointer" }}
														>
															APPLY
														</p>
													</Dropdown.Item>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
						</DropdownButton>

						<div
							style={{ cursor: "pointer" }}
							className="card-link d-flex align-items-center "
							onClick={resetAll}
						>
							<p className="pb-0 mb-0">Reset</p>
						</div>
					</div>
				</div>
			</Col>

			{/* <Col lg={{ span: 2 }}>
				{" "}
				<div>
					<div id="copy" className="mb-0 d-flex justify-content-center">
						<Button
							className="text-primary border-primary me-2 fs-6"
							variant="white"
							value="Apply"
							type="button"
							style={{ width: "6rem", fontSize: "80%" }}
							onClick={resetAll}
						>
							Reset
						</Button>
						<Button
							className="text-white fw-bold border-primary fs-6"
							variant="primary"
							value="Apply"
							type="button"
							style={{ width: "6rem", fontSize: "80%" }}
							onClick={applyAll}
						>
							Apply
						</Button>
					</div>
				</div>
			</Col> */}
		</Row>
	);
};

export default PropertiesFilters;
