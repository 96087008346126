import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { MdOutlineClear } from "react-icons/md";
import slugify from "slugify";
import CartContext from "../context/CartContext";
import ImageSlider from "./image-slider";

function MapCardModal(props) {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
	} = React.useContext(CartContext);

	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}

	var floors = [];
	if (props.property?.node?.floorsAvailable !== null) {
		floors =
			props.property?.node?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	if (props.property === undefined) return <></>;

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="rounded"
		>
			<Modal.Body className="p-0">
				<div className="bg-white h-100 px-0 mx-0 position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 2 }}
					>
						{cart?.filter(
							(e) => e.airtableId === props.property?.node.airtableId
						).length > 0 && (
							<FaHeart
								style={{ cursor: "pointer", zIndex: 3 }}
								onClick={() => OnRemoveFromCart(props.property.node)}
								className="fs-4 text-dark-blue"
							/>
						)}
						{cart?.filter(
							(e) => e.airtableId === props.property?.node.airtableId
						).length < 1 && (
							<FaRegHeart
								style={{ cursor: "pointer", zIndex: 3 }}
								onClick={() => OnAddToCart(props.property?.node)}
								className="fs-4 text-med-grey"
							/>
						)}
					</div>
					<div
						className="px-3 pt-2 position-absolute start-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={props.onHide}
						/>
					</div>

					<Link
						to={`/listings/${slugify(props.property?.node.name)}-${
							props.property?.node.fixedId
						}`}
					>
						<div
							className="bg-white h-100 px-0 mx-0"
							onMouseEnter={() => {
								onLocationChangeScroll(
									props.property?.node.locationLatitude,
									props.property?.node.locationLongitude
								);
							}}
						>
							<Row
								className="px-0 mx-0 g-0  align-items-center"
								style={{ borderBottom: "1px solid #f5f5f5" }}
							>
								<Col xs={12}>
									<ImageSlider
										imageDataArr={props.property?.node.photos}
										height="320px"
									/>
								</Col>
								<Col
									className="ps-4 px-lg-4 pt-3 pt-lg-1 pb-1 text-black"
									style={{ minHeight: "100%" }}
									xs={12}
								>
									<h3 className="fs-4 pb-2">{props.property?.node.name}</h3>

									<blockquote>
										{props.property?.node.type === "Serviced Office" && (
											<>
												<p className="mb-1">Private offices from</p>
												<h5 className="mt-1">
													£
													{`${
														props.property?.node.rentPM === null ||
														props.property?.node.rentPM === undefined
															? "POA"
															: `${round5(
																	props.property?.node.rentPM
															  ).toLocaleString("en-UK")}pcm`
													}`}
												</h5>
											</>
										)}
										{(props.property?.node.type === "Managed Office" ||
											props.property?.node.type === "Flex Lease") && (
											<>
												<p className="mb-1">Private floors from</p>
												<h5 className="mt-1">
													£
													{`${
														floors[0]?.rentPM === null ||
														floors[0]?.rentPM === undefined
															? "POA"
															: `${round5(floors[0]?.rentPM).toLocaleString(
																	"en-UK"
															  )}pcm`
													}`}
												</h5>
											</>
										)}
									</blockquote>
								</Col>
							</Row>
						</div>
					</Link>
				</div>
			</Modal.Body>
			{/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
		</Modal>
	);
}

export default MapCardModal;
