import MultiRangeSlider from "multi-range-slider-react";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CartContext from "../context/CartContext";

const DoubleRangeSlider = ({
	values,
	minMaxLimits,
	radio,
	setter,
	addPoundSign,
	filterValues,
	setter2,
}) => {
	const { settingVal } = React.useContext(CartContext);
	const [minValue, setMinValue] = useState(values.min);
	const [maxValue, setMaxValue] = useState(values.max);
	const [minValuePerDesk, setMinValuePerDesk] = useState(values.minPerDesk);
	const [maxValuePerDesk, setMaxValuePerDesk] = useState(values.maxPerDesk);
	const [perDesk, setPerDesk] = useState(values.perDesk);

	const [resetTemp, setResetTemp] = useState(false);

	const [minValueLabel, setMinValueLabel] = useState(`${minMaxLimits.min}`);
	const [maxValueLabel, setMaxValueLabel] = useState(`${minMaxLimits.max}`);
	const [minValueLabelPerDesk, setMinValueLabelPerDesk] = useState(
		`£${minMaxLimits.minPerDesk}`
	);
	const [maxValueLabelPerDesk, setMaxValueLabelPerDesk] = useState(
		`£${minMaxLimits.maxPerDesk}`
	);

	if (addPoundSign) {
		setMinValueLabel(`£${minMaxLimits.min}`);
		setMaxValueLabel(`£${minMaxLimits.max}`);
	}

	const handleInput = (e) => {
		if (radio && perDesk) {
			setMinValuePerDesk(e.minValue);
			setMaxValuePerDesk(e.maxValue);
		} else {
			setMinValue(e.minValue);
			setMaxValue(e.maxValue);
		}
	};

	const radioSetter = () => {
		setPerDesk(!perDesk);
	};

	useEffect(() => {
		if (radio) {
			const valNew = {
				max: maxValue,
				min: minValue,
				maxPerDesk: maxValuePerDesk,
				minPerDesk: minValuePerDesk,
				perDesk: perDesk,
			};
			valNew.perDesk = perDesk;
			setter(valNew);
		} else {
			const valNew = { max: maxValue, min: minValue };
			setter(valNew);
		}
	}, [minValue, maxValue, minValuePerDesk, maxValuePerDesk, perDesk]);

	useEffect(() => {
		if (radio) {
			var temp = filterValues;
			setMinValuePerDesk(temp.minPerDesk);
			setMaxValuePerDesk(temp.maxPerDesk);
			setMinValue(temp.min);
			setMaxValue(temp.max);
			setPerDesk(temp.perDesk);
		} else {
			var temp = filterValues;
			setMinValue(temp.min);
			setMaxValue(temp.max);
		}
	}, [setter2]);

	return (
		<>
			{radio && (
				<div className="d-flex justify-content-center">
					<Form.Check
						checked={!perDesk}
						onClick={radioSetter}
						type="radio"
						label="Total"
						className="pb-1 pe-4"
					/>

					<Form.Check
						checked={perDesk}
						onClick={radioSetter}
						type="radio"
						label="Per Desk"
						className="pb-1"
					/>
				</div>
			)}
			<MultiRangeSlider
				className="border-0 multi-slider"
				min={perDesk ? minMaxLimits.minPerDesk : minMaxLimits.min}
				max={perDesk ? minMaxLimits.maxPerDesk : minMaxLimits.max}
				// step={5}
				labels={[
					perDesk
						? `Min price: £${minValuePerDesk}`
						: `${
								addPoundSign
									? "Min price: £" + minValue
									: "Min Desks: " + minValue
						  }`,
					perDesk
						? `Max price: £${maxValuePerDesk}`
						: `${
								addPoundSign
									? "Max price: £" + maxValue
									: "Max Desks: " + maxValue
						  }`,
				]}
				minValue={perDesk ? minValuePerDesk : minValue}
				maxValue={perDesk ? maxValuePerDesk : maxValue}
				// steps={5000}
				step={radio ? (!perDesk ? 5000 : 10) : 1}
				stepOnly={radio ? true : false}
				ruler={false}
				onInput={(e) => {
					handleInput(e);
				}}
			/>
		</>
	);
};

export default DoubleRangeSlider;
